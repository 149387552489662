import { Button, Grid, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import fishingnetshomebanner from '../assets/fishingnetshomebanner.png';
import fishingnets from '../assets/fishingnets.png';
import React from 'react';
import { useTheme } from '@emotion/react';
import Process from '../components/Process';
import MeshedNets from './MeshedNets';
import { useNavigate } from "react-router-dom";
import Contact from './Contact';

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: '5rem',
		[theme.breakpoints.down('md')]: {
			marginTop: '0rem',
		},
		[theme.breakpoints.only('md')]: {
			marginTop: '2rem',
		},
	},
	subscriptText: {
		marginTop: '2rem',
		fontFamily: 'Poppins',
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
			display: 'flex',
		},
	},
	imagestyles: {
		filter: 'drop-shadow(0px 5px 5px #000)',
		[theme.breakpoints.only('md')]: {
			marginLeft: '4rem'
		},
		[theme.breakpoints.between('1539','1681')]: {
			width:"120%"
		},
		
	},
	offersButton: {
		marginTop: '30px',
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
		},
	},
	newColor: {
		fontFamily: 'Poppins',
		lineHeight: '80px',
		fontSize:'76px',
		[theme.breakpoints.down('md')]: {
			fontSize: '30px',
			display: 'flex',
			align:'center'
		},
		[theme.breakpoints.only('lg')]: {
			fontSize: '48px',
			lineHeight: '70px'
		},
		[theme.breakpoints.only('md')]: {
			fontSize: '36px',
			lineHeight: '50px'
		},
		
		[theme.breakpoints.down('sm')]: {
			alignContent: 'center',
			display: 'flex',
			lineHeight: '42px',
		},
	},
	secondItemWrapper: {
		marginTop: '12rem',
		fontFamily: 'Poppins',
		[theme.breakpoints.only('md')]: {
			marginTop: '7rem',
		},
		[theme.breakpoints.down('md')]: {
			marginTop: '6rem',
		},
	},
	secondItem: {
		fontFamily: 'Poppins',
		fontSize: '20px',
		display: 'flex',
		textAlign: 'center',
		lineHeight: '33px',
		letterSpacing: '1px',
		[theme.breakpoints.down('sm')]: {
			lineHeight: 'unset',
			letterSpacing: 'unset',		},
	},
	boxsizing: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexShrink: 1,
		marginTop: '3rem',
		// flexGrow: 1,
		position: 'relative',
		// alignSelf: 'stretch',
		// width: '1100px',
		// height: '466px',
		backgroundColor: 'rgb(242, 242, 242)',
		borderRadius: '16px',
		boxShadow: 'rgb(51 51 51 / 10%) 0px 4px 24px',
		opacity: 1,
		border: '0px',
	},
	innerboxWrapper: {
		padding: '3rem',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: '9rem',
		backgroundColor: 'rgb(255, 255, 255)',
		borderRadius: '16px',
		boxShadow: 'rgb(51 51 51 / 10%) 0px 4px 32px',
		opacity: 1,
		border: '0px',
		
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column-reverse',
			marginTop: '6rem',
			padding: '2rem'

		},
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column-reverse',
			marginTop: '6rem',
			padding: '1rem'

		},
		[theme.breakpoints.only('md')]: {
			marginTop: '6rem',
		},
	},
	doubleboxwrapper: {
		marginTop: '7rem',
		display: 'flex',
		justifyContent: 'center',
	},
	typoText: {
		fontSize: '48px',
		fontWeight: 600,
		fontFamily: 'Poppins',
		lineHeight: '62px',
		marginBottom: '2rem',
		[theme.breakpoints.between('1200','1601')]: {
			fontSize: '32px',
			fontWeight: 600,
			lineHeight: '40px',
		},
		[theme.breakpoints.only('md')]: {
			fontSize: '32px',
			fontWeight: 600,
			lineHeight: '40px',
		},
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
			fontSize: '28px',
			fontWeight: 600,
			lineHeight: '40px',
		},
		[theme.breakpoints.down('md')]: {
			textAlign: 'center',
			fontSize: '24px',
			fontWeight: 600,
			lineHeight: '30px',
		},
	},
	typotext2: {
		fontFamily: 'Poppins',
		fontSize: '20px',
		display: 'flex',
		marginBottom: '2rem',
		[theme.breakpoints.down('md')]: {
			textAlign: 'center',
			fontSize: '22px',
		},
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
			fontSize: '20px',
		},
	},
	tryForFreeButton: {
		backgroundColor: 'rgb(49, 100, 244)',
		height: '50px',
		textTransform: 'none',
		width: 'auto',
		fontWeight: '600',
		borderRadius: '8px',
		[theme.breakpoints.down('md')]: {
		},
	},
	imagefish: {
		textAlign: 'center',
	},
	buttonTrialWrapper: {
		[theme.breakpoints.down('md')]: {
			textAlign: 'center',
			marginBottom: '1rem',
			fontSize: '18px',
		},
	},
	featureHead: {
		marginTop: '7rem',
		textAlign: 'center',
		fontWeight: '600',
		fontFamily: 'Poppins',
		[theme.breakpoints.only('xs')]: {
			fontSize: '2rem'
		},
	},
}));

export default function Home() {
	const classes = useStyles();
	const theme = useTheme();
	const handy = useMediaQuery(theme.breakpoints.down('md'));

	const navigate = useNavigate();


	return (
		<div className={classes.root}>
			<Grid
				container
				flexDirection={handy ? 'column-reverse' : 'row'}
				className={classes.wrapperContainer}
				justifyContent="center"
				alignContent={'center'}
			>
				<Grid item lg={6} md={6} sm={10} xs={10}>
					<Typography
						variant="p"
						fontWeight={'400'}
						className={classes.newColor}
						align="center"
					>
						The new way of fishing is here with Excel Nets
					</Typography>
					<Typography fontSize={'20px'} className={classes.subscriptText}>
						With intricate Net - Mesh features and affordable pricing
					</Typography>
					<div className={classes.offersButton}>
						<Button
							variant="contained"
							color="primary"
							href="#features"
							className={classes.tryForFreeButton}
						>
							Check out our specifications
						</Button>
					</div>
				</Grid>
				<Grid item lg={6} md={6} sm={10} xs={10}>
					<img
						src={fishingnetshomebanner}
						alt="fishinghome"
						width={'100%'}
						height="100%"
						className={classes.imagestyles}
						color={theme.palette.primary.main}
					/>
				</Grid>
			</Grid>
			<Grid container justifyContent="center">
				<Grid
					item
					className={classes.secondItemWrapper}
					lg={10}
					md={10}
					sm={10}
					xs={8}
				>
					<Typography variant="p" className={classes.secondItem}>
						Our customers span across Indian and International governments. We
						sells nets to domestic Indian market and export nets and twine as
						well.
					</Typography>
				</Grid>
			</Grid>
			<Grid container justifyContent={'center'}>
				<Grid item lg={8} md={8} sm={10} xs={9}>
					<Grid container className={classes.innerboxWrapper}>
						<Grid item lg={6} md={6} sm={10} xs={10}>
							<Typography variant="h2" className={classes.typoText}>
								Get the best results
							</Typography>
							<Typography variant="p" className={classes.typotext2}>
								Because we understand your requirements
							</Typography>
							<div className={classes.buttonTrialWrapper}>
								<Button
									variant="contained"
									className={classes.tryForFreeButton}
									onClick={() => navigate('/contact')}
								>
									Contact Us
								</Button>
							</div>
						</Grid>
						<Grid
							item
							lg={6}
							md={6}
							sm={10}
							xs={10}
							className={classes.imagefish}
						>
							<img
								src={fishingnets}
								alt="fishingnets"
								width="80%"
								height="80%"
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Typography variant="h3" className={classes.featureHead} id="features">
				Features
			</Typography>
			<MeshedNets />
			<Process />
		</div>
	);
}
