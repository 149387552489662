import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import netsmore from '../assets/inception.png';
import aboutusmachine from '../assets/about-us-machine.png';
import mobilestars from '../assets/mobilestars.png';
import fivestar from '../assets/five-star.png';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		marginTop: '3rem',
		[theme.breakpoints.down('md')]: {
			// paddingTop: '1.3rem',
			marginTop: '0rem',
			// flexDirection: 'column-reverse',
		},
	},
	root2: {
		display: 'flex',
		marginTop: '3rem',
		[theme.breakpoints.down('md')]: {
			paddingTop: '1.3rem',
            flexDirection: 'column'
		},
	},
	aboutus: {
		textAlign: 'center',
	},
	title: {
		fontFamily: 'Poppins',
		fontWeight: '400',
		[theme.breakpoints.only('md')]: {
			fontSize: '36px',
		},
		[theme.breakpoints.down('md')]: {
			marginTop: '3rem',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '34px',
		}
	},
	content1: {
		fontFamily: 'Poppins',
		fontSize: '25px',
		marginTop: '3rem',
		[theme.breakpoints.only('md')]: {
			fontSize: '19px'
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '	22px'
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '	20px'
		},
	},
	imgwrapepr: {
		marginTop: '6rem',
		[theme.breakpoints.down('sm')]: {
			marginTop: '0rem',
		},
	},
	firstImage:{
		marginTop: '6rem',
		[theme.breakpoints.down('md')]: {
			marginTop: '5rem',
		},
	}
	,
	imgwrapepr2: {
		marginTop: '6rem',
		// display: 'flex',
		textAlign: 'center',
		[theme.breakpoints.only('xs')]:{
			marginTop: '5rem'
		}
	},
	imgactual: {
		borderRadius: '20px',
		filter: 'drop-shadow(3px 5px 12px #234)',
		
	},
	nettinginception: {
		borderRadius: '20px',
		filter: 'drop-shadow(3px 5px 12px #234)',
		[theme.breakpoints.only('md')]: {
			width: '80%',
			height: '80%'
		},
	},
	ratingstypo: {
		marginTop: '1.8rem',
		textAlign: 'center',
		fontFamily: 'Calligraffitti',
		fontSize: '38px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '28px',
		},
	},
	imgratings: {
		textAlign: 'center',
	},
	imageratingswrapper: {
		justifyContent: 'center',
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},
	mobileStars: {
		justifyContent: 'center',
		textAlign: 'center',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	ratingscontainer: {
		marginTop: '7rem',
		[theme.breakpoints.down('md')]: {
			marginTop: '2rem',
		},
	},
	imagestars2: {
		marginLeft: '1rem',
	},
	parent: {
		// marginTop: '7rem',
		[theme.breakpoints.down('md')]: {
			marginTop: '0rem',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '2.3rem',
		},
	},
}));

export default function AboutUs() {
	const classes = useStyles();
	return (
		<div className={classes.parent}>
			<Grid
				container
				justifyContent={'center'}
				alignItems="center"
				className={classes.root}
				flexDirection={'column'}
			>
				<Grid item className={classes.aboutus} lg={9} md={9} sm={10} xs={10}>
					<Typography variant="h3" className={classes.title}>
						About Us
					</Typography>
					<Typography className={classes.content1}>
						Excel nets inception was in the year 2004. Excel nets is rooted in
						research and development since its inception. Prior to making nets,
						the Excel Nets team has and is still rooted in fishing net machine
						manufacturing through its sister concern Excel Fabricators, a key
						supplier to Japanese Net Manufacturing machine firms.
					</Typography>
				</Grid>
				<Grid
					item
					lg={10}
					md={10}
					sm={10}
					xs={10}
					className={classes.firstImage}
				>
					<img
						src={aboutusmachine}
						alt="about-us"
						width="100%"
						height="100%"
						className={classes.imgactual}
					/>
				</Grid>
			</Grid>
			<Grid
				container
				flexDirection={'row'}
				justifyContent="center"
				className={classes.ratingscontainer}
			>
				<Grid
					item
					lg={3}
					md={3}
					sm={10}
					xs={10}
					className={classes.mobileStars}
				>
					<img src={mobilestars} alt="about-us" width="70%" height="100%" />
				</Grid>
				<Grid
					item
					lg={3}
					md={3}
					sm={6}
					xs={6}
					className={classes.imageratingswrapper}
				>
					<img src={fivestar} alt="about-us" width="100%" height="100%" />
				</Grid>

				<Grid
					item
					lg={4}
					md={6}
					sm={10}
					xs={10}
					style={{ textAlign: 'center' }}
				>
					<Typography variant="h5" className={classes.ratingstypo}>
						We strive towards customer satisfaction
					</Typography>
				</Grid>
				<Grid
					item
					lg={3}
					md={3}
					sm={6}
					xs={6}
					className={classes.imageratingswrapper}
				>
					<img
						src={fivestar}
						alt="about-us"
						width="100%"
						height="100%"
						className={classes.imagestars2}
					/>
				</Grid>
			</Grid>
			<Grid
				container
				justifyContent={'center'}
				alignItems="center"
				className={classes.root2}
				flexDirection={'row'}
			>
				<Grid item className={classes.aboutus} lg={6} md={6} sm={10} xs={10}>
					<Typography variant="h3" className={classes.title}>
						Inception
					</Typography>
					<Typography className={classes.content1}>
						The knowledge of net making machine workings and the intricacies
						involved in machine setting gained from the netting machine
						manufacturing process, spear headed the inception of Excel nets to
						product quality nets.
					</Typography>
				</Grid>
				<Grid item lg={6} md={6} sm={8} xs={10} className={classes.imgwrapepr2}>
					<img
						src={netsmore}
						alt="about-us"
						width="80%"
						height="80%"
						className={classes.nettinginception}
					/>
				</Grid>
			</Grid>
		</div>
	);
}
