import React from 'react';
import Features from '../components/Features';
import filament from '../assets/filament.png';
import twist from '../assets/twist.png';
import scale from '../assets/scale.png';
import meshsize from '../assets/meshsize.png';
import depth from '../assets/depth.png';
import netmesh from '../assets/net-length.png';
import knottype from '../assets/knottype.png';
import selvage from '../assets/selvage.png';



export default function MeshedNets() {
	const details = [
		{
            imgsrc: filament,
			head: 'Material',
			preHead: null,
			subText: {
				numeric: false,
				numericValues: [],
				text: 'Nylon - Multi filament',
			},
			content: "With Nylon multi filament yarn it facilitates the mesh, knots to be stronger during for longer period of time.",
		},
		{
			head: 'Denier',
            imgsrc: scale,
			preHead: 'Diameter of the yarn',
			subText: {
				numeric: true,
				numericValues: ['210 D', '420 D', '630 D', '840 D'],
				text: 'Common Denier are 210 D and 420 D. Higher Denier will be more tough. Typically not mentioned by the fishermen.',
			},
			content: null,
		},
        {
			head: 'Twists',
            imgsrc: twist,
			preHead: 'Ratio of Twists',
			subText: {
				numeric: true,
				numericValues: ['1/2','1/3','2/2','2/3','3/3','4/3','5/3'],
				text: null,
			},
			content: '<b>Common Twists</b>: 1/2, 1/3, 2/2, 2/3',
		},
        {
			head: 'Size',
            imgsrc: meshsize,
			preHead: 'Net Mesh Size',
			subText: {
				numeric: true,
				numericValues: ['Min: 10mm','Max: 300mm'],
				text: 'Minimum Size increments is 0.5mm increments.',
			},
			content: 'Example- 10.5 mm, 11 mm, 11.5 mm , 12 mm. <br/><b>Common Mesh Sizes:</b> 10 mm, 12 mm, 19 mm, 22 mm, 25 mm, 51/54 mm, 64 mm',
		},
        {
			head: 'Depth',
            imgsrc: depth,
			preHead: 'Net Mesh Depth',
			subText: {
				numeric: null,
				numericValues: null,
				text: 'Measurement Unit - MD (Mesh Depth)',
			},
			content: 'Maximum Depth: 664 MD (664 Shuttles).<br/> <b>Common:</b> 600 Shuttles, 100 Shuttles, 50 Shuttles',
		},
        {
			head: 'Length',
            imgsrc: netmesh,
			preHead: 'Length of the net',
			subText: {
				numeric: null,
				numericValues: null,
				text: 'Kg to Length Equation. We make long nets.',
			},
			content: '1/2 - 10 mm small size nets - 40 Kg will be 300 meters long',
		},
        {
			head: 'Type',
            imgsrc: knottype,
			preHead: 'Knot Type',
			subText: {
				numeric: true,
				numericValues: ['Single', 'Double'],
				text: 'Two types of knots are possible. Catch weight and water flow determine type of knot.',
			},
			content:null,
		},
        {
			head: 'Selvedge',
            imgsrc: selvage,
			preHead: null,
			subText: {
				numeric: null,
				numericValues:null,
				text: 'To reinforce the edges of the nets for seasonal rough seas, sometimes fishermen request for selvedge.',
			},
			content:'The selvedge twines are typically mentioned and sometimes might be in a different color.<br/><br/> <b>Example-</b> for 1/3 twine net we might use a selvedge 2/2 or 2/3 twine. <br/><br/>We can provide selvedge for the nets as well if mentioned ahead of time.',
		}
	];

	return (
		<div>
			{details.map((item, idx) => {
				return (
					<div key={item.head} >
						<Features item={item} index={idx}/>
					</div>
				);
			})}
		</div>
	);
}
