import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	root: { marginTop: '9rem' ,
	[theme.breakpoints.down('sm')]: {
		marginTop: '6rem',
	},},
	imageContainer: {
		textAlign: 'center',
	},
	imageActual: {
		[theme.breakpoints.only('md')]: {
			width: '40%',
			height: '40%'
		}
	},
	textContainer: {
		[theme.breakpoints.down('md')]: {
			marginTop: '2rem',
			textAlign: 'center'
		}
	},
	typographyHead: {
		fontFamily: 'Poppins',
		fontWeight: '400',
		[theme.breakpoints.only('xs')]: {
			fontSize: '2rem'
		},
	},
	typographySubText: {
		fontFamily: 'Poppins',
		marginTop: '1rem',
		[theme.breakpoints.only("sm")]: {
			fontSize: '18px'
		}
		
	},
	typographyContent: {
		fontFamily: 'Poppins',
		marginTop: '1rem',
		[theme.breakpoints.only("sm")]: {
			fontSize: '18px'
		}
	},
	preHead: {
		color: '#7F7F7F',
		letterSpacing: '3px',
		fontSize: '12px',
		textTransform: 'uppercase',
	},
	cardItem: {
		textAlign: 'center',
		minHeight: '3rem',
		borderRadius: '16px',
		alignItems: 'center',
		border: '1px solid #7F7F7F',
		justifyContent: 'center',
		display: 'flex',
		margin: '1rem',
	},
	containerCard: {
		[theme.breakpoints.down('md')]: {
			display: 'flex',
			justifyContent: 'center',
			alignContent: 'center',
			textAlign: 'center'
		}
	},
	cardText: {},
	preHeadWrapper:{
		[theme.breakpoints.down('md')]: {marginBottom: '1rem'}

	}
}));

export default function Features({ item, index }) {
	console.log(item);

	const classes = useStyles();

	const { head, preHead, subText, content, imgsrc } = item;

    const invert = index % 2 !== 0

	const theme = useTheme();
	const breakpoint = useMediaQuery(theme.breakpoints.down('md'));

	const getCards = (subText) => (
		<Grid container alignItems="center" className={classes.containerCard}>
			{subText.numericValues.map((value) => {
				const  LESS_DATA = subText.numericValues.length <=3
				console.log(value);
				return (
					<Grid item lg={LESS_DATA ? 4: 2} md={LESS_DATA ? 4 : 4} sm={3} xs={4} className={classes.cardItem}>
						<Typography variant={LESS_DATA && breakpoint? "p": "h5"} className={classes.cardText}>
							{value}
						</Typography>
					</Grid>
				);
			})}
		</Grid>
	);

	return (
		<Grid
			container
			justifyContent="center"
			alignItems="center"
			className={classes.root}
            flexDirection={!invert ? 'row' : 'row-reverse'}
		>
			<Grid
				item
				lg={5}
				md={5}
				sm={10}
				xs={10}
				className={classes.imageContainer}
			>
				<img
					src={imgsrc}
					alt="newima"
					width="20%"
					height="20%"
					className={classes.imageActual}
				/>
			</Grid>
			<Grid
				item
				lg={5}
				md={5}
				sm={10}
				xs={10}
				className={classes.textContainer}
			>
				{preHead && (
					<div className={classes.preHeadWrapper}>
					<Typography variant="p" className={classes.preHead}>
						{preHead}
					</Typography>
					</div>
				)}
				<Typography variant="h4" className={classes.typographyHead}>
					{head}
				</Typography>
				{subText?.numeric ? getCards(subText) : null}
				<Typography className={classes.typographySubText}>
					{subText?.text}
				</Typography>
				{content && (
					<Typography dangerouslySetInnerHTML={{__html: content}} className={classes.typographyContent} />
				)}
			</Grid>
		</Grid>
	);
}
