import { useTheme } from '@emotion/react';
import { Grid, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AboutUs from '../views/AboutUs';
import Contact from '../views/Contact';
import Home from '../views/Home';
import MeshedNets from '../views/MeshedNets';
import Footer from './Footer';
import Navbar from './Navbar';

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: '3rem',
		paddingBottom: '3rem',
		[theme.breakpoints.down('sm')]: {
			paddingTop: '1.3rem',
		},
	},
}));

export default function Page() {
	const classes = useStyles();
	const theme = useTheme();
	const handy = useMediaQuery(theme.breakpoints.down('md'));

	let size = 8;
	if (handy === false) size = 8;
	else size = 12;

	return (
		<Router>
			<Grid container justifyContent="center" className={classes.root}>
				<Grid item lg={size} md={size} sm={size} xs={size}>
					<Navbar />
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/about-us" element={<AboutUs />} />
						<Route path="/multi-filament-meshednets" element={<MeshedNets />} />
						<Route path="/contact" element={<Contact />} />
					</Routes>
                    <Footer />
				</Grid>
			</Grid>
		</Router>
	);
}
