import {
	Alert,
	Button,
	Grid,
	Slide,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import contactus from '../assets/contactus.png';
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	textfields: {
		backgroundColor: 'white',
		// borderRadius: '13px',
		width: '100%',
		[theme.breakpoints.down('md')]: {
			// width: '20rem',
		},
	},
	notchedOutline: {
		// borderColor: 'white !important',
	},
	labels: {
		color: 'grey',
	},
	subHeading: {
		color: '#62625f',
		marginTop: '1.5rem',
		marginBottom: '1.5rem',
		[theme.breakpoints.down('md')]: {
			// textAlign: 'center'
		},
	},
	root: {
		marginTop: '5rem',
		[theme.breakpoints.down('md')]: {
			marginTop: '1rem',
		},
	},
	heading: {
		fontFamily: 'Poppins',
		[theme.breakpoints.only('md')]: {
			fontSize: '29px',
		},
		[theme.breakpoints.down('md')]: {
			// textAlign: 'center'
		},
	},
	containerRoot: {
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
		},
	},
	buttonMsg: {
		marginTop: '1rem',
		backgroundColor: 'rgb(49, 100, 244)',
		height: '50px',
		width: '100%',
		textTransform: 'none',
		fontWeight: '600',
		borderRadius: '8px',
		[theme.breakpoints.down('md')]: {
			// width: '20rem',
		},
		[theme.breakpoints.only('md')]: {
			// width: '23rem',
		},
	},
	imagewr: {
		[theme.breakpoints.only('md')]: {
			height: '80%',
		},
	},
	alerts: {
		width: '100%',
	},
}));

export default function Contact() {
	const classes = useStyles();

	const [name, setName] = useState(null);
	const [email, setEmail] = useState(null);
	const [message, setMessage] = useState(null);
	const [incorrectMail, setIncorrectMail] = useState(false);
	const [maxLength, setMaxLength] = useState(false);
	const [alert, setAlert] = useState(false);
	const [success, setSuccess] = useState(false);

	const form = useRef();

	const theme = useTheme();
	const handy = useMediaQuery(theme.breakpoints.down('sm'));

	const validateEmail = (value) => {
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
			setEmail(value);
			setIncorrectMail(false);
		} else {
			setIncorrectMail(true);
			setEmail(value);
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (
			email !== '' &&
			name !== '' &&
			message !== '' &&
			email !== null &&
			name !== null &&
			message !== null && !incorrectMail && !maxLength
		) {
			emailjs
				.sendForm(
					process.env.REACT_APP_SERVICE_ID,
					process.env.REACT_APP_TEMPLATE_ID,
					form.current,
					process.env.REACT_APP_PUBLIC_KEY
				)
				.then(
					(result) => {
						console.log(result.text);
					},
					(error) => {
						console.log(error.text);
					}
				);
			setSuccess(true);
			document.getElementById("formsdata").reset();
			setMessage(null);
		} else setAlert(true);
	};

	return (
		<div className={classes.root}>
			<Grid container className={classes.containerRoot}>
				<Grid item lg={8} md={8} sm={10} xs={10}>
					<img
						src={contactus}
						alt="contactus"
						width="100%"
						height="100%"
						className={classes.imagewr}
					/>
				</Grid>
				<Grid item lg={4} md={4} sm={10} xs={10}>
					<form ref={form} id="formsdata">
						<Grid
							container
							flexDirection={'column'}
							spacing={2}
							justifyContent="center"
						>
							<Grid item lg={8} md={8} sm={10} xs={10}>
								<Typography variant="h3" className={classes.heading}>
									Contact Us
								</Typography>
							</Grid>
							<Grid
								item
								lg={6}
								md={6}
								sm={10}
								xs={10}
								className={classes.subHeading}
							>
								<Typography>Feel free to get int touch.</Typography>
							</Grid>
							<Grid item lg={4} md={4} sm={10} xs={10}>
								<Typography variant="p" className={classes.labels}>
									Name*
								</Typography>
							</Grid>
							<Grid item lg={10} md={10} sm={10} xs={12}>
								<TextField
									className={classes.textfields}
									name="from_name"
									onChange={(e) => setName(e.target.value)}
									required
									size={handy ? 'small': 'normal'}
									InputProps={{
										classes: {
											notchedOutline: classes.notchedOutline,
										},
									}}
									helperText={name === '' ? 'This field is required' : ''}
									error={name === ''}
									variant="outlined"
								></TextField>
							</Grid>
							<Grid item lg={4} md={4} sm={10} xs={10}>
								<Typography variant="p" className={classes.labels}>
									E-mail*
								</Typography>
							</Grid>
							<Grid item lg={10} md={10} sm={10} xs={12}>
								<TextField
									className={classes.textfields}
									variant="outlined"
									required
									size={handy ? 'small': 'normal'}
									type="email"
									onChange={(e) => validateEmail(e.target.value)}
									name="required_mail"
									helperText={
										email === ''
											? 'This field is required'
											: incorrectMail
											? 'Please enter a valid email address'
											: ''
									}
									error={email === '' || incorrectMail}
									InputProps={{
										classes: {
											notchedOutline: classes.notchedOutline,
										},
									}}
								></TextField>
							</Grid>
							<Grid item lg={4} md={4} sm={10} xs={10}>
								<Typography variant="p" className={classes.labels}>
									Message*
								</Typography>
							</Grid>
							<Grid item lg={10} md={10} sm={10} xs={12}>
								<TextField
									className={classes.textfields}
									variant="outlined"
									onChange={(e) => {
										if (e.target.value.length <= 90) {
											console.log('innnn');

											setMessage(e.target.value);
											setMaxLength(false);
										} else setMaxLength(true);
									}}
									name="message"
									multiline
									maxRows={2}
									size={handy ? 'small': 'normal'}
									error={message === '' || maxLength}
									value={!success && message!== "" ? message : ""}
									required
									helperText={
										message === ''
											? 'This field is required'
											: maxLength
											? 'Max character limit: 150'
											: ''
									}
									InputProps={{
										classes: {
											notchedOutline: classes.notchedOutline,
										},
									}}
								></TextField>
							</Grid>
							<Grid item lg={10} md={10} sm={10} xs={10}>
								<Slide direction="left" in={alert} mountOnEnter unmountOnExit>
									<Alert severity="error" className={classes.alerts}>
										Enter All required fields!
									</Alert>
								</Slide>
								<Slide direction="left" in={success} mountOnEnter unmountOnExit>
									<Alert severity="success" className={classes.alerts}>
										We have received your message. We'll get back to you
										shortly.
									</Alert>
								</Slide>
							</Grid>
							<Grid item lg={10} md={10} sm={10} xs={12}>
								<Button
									variant="contained"
									className={classes.buttonMsg}
									onClick={handleSubmit}
								>
									Send Message
								</Button>
							</Grid>
						</Grid>
					</form>
				</Grid>
			</Grid>
		</div>
	);
}
