import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	typo: {
		textAlign: 'center',
		fontFamily: 'center',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column-reverse',
		},
	},
	typoRows: {
		textAlign: 'center',
		fontFamily: 'Poppins',
		fontSize: '16px',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column-reverse',
		},
	},
	containers: {
		borderRadius: '16px',
	},
	typoTitle: {
		fontFamily: 'Poppins',
        [theme.breakpoints.only('md')]:{
            fontSize: '20px',
            lineHeight: '33px'
        },
		[theme.breakpoints.only('xs')]:{
            fontSize: '20px',
        }
	},
	tableWrapper: {
		marginTop: '4rem',
	},
	titleWrapper: {
		marginTop: '8rem',
		[theme.breakpoints.only('xs')]:{
            marginTop: '6rem',
        }
	},
	cell:{
		[theme.breakpoints.only('xs')]: {
			padding: '7px'
		},
		
	},
	productionTypo: {
		[theme.breakpoints.only('xs')]: {
			fontSize: '2rem'
		},
	},
	containerWrapper:{
		marginTop: '2rem',
		textAlign: 'center',
		flexDirection: 'column',
		alignContent: 'center',
		[theme.breakpoints.only('xs')]: {
			marginTop: '4rem',
		},
	}
}));

export default function Process() {
	const classes = useStyles();
	const stepInfo = [
		{
			step: 1,
			title: 'Yarn Procurement',
			days: 5,
		},
		{
			step: 2,
			title: 'Yarn Twisting',
			days: 5,
		},
		{
			step: 3,
			title: 'Net Weaving',
			days: '2 - 3',
		},
		{
			step: 4,
			title: 'Net Boiling',
			days: 1,
		},
		{
			step: 5,
			title: 'Net Resin Treatment',
			days: 1,
		},
		{
			step: 6,
			title: 'Net Dying',
			days: 1,
		},
		{
			step: 7,
			title: 'Net Length Stretching',
			days: 1,
		},
		{
			step: 8,
			title: 'Packing',
			days: 1,
		},
		{
			step: 9,
			title: 'Shipping',
			days: 2,
		},
	];
	return (
		<Grid
			container
			style={{
				
			}}
			justifyContent="center"
			className={classes.containerWrapper}
		>
			<Grid
				item
				lg={8}
				md={10}
				sm={10}
				xs={10}
				className={classes.tableWrapper}
			><Typography variant="h3" fontFamily={'Poppins'} fontWeight="600" className={classes.productionTypo}>
                Production Cycle
                </Typography>
            </Grid>
			<Grid item lg={8} md={10} sm={10} xs={10} className={classes.tableWrapper}>
				<TableContainer
					component={Paper}
					elevation={3}
					className={classes.containers}
				>
					<Table lx aria-label="spanning table">
						<TableHead>
							<TableRow>
								<TableCell align="center">
									<Typography className={classes.typoRows} variant="h6">
										<b>Step</b>
									</Typography>
								</TableCell>
								<TableCell align="center">
									<Typography className={classes.typoRows} variant="h6">
										<b>Step Title</b>
									</Typography>
								</TableCell>
								<TableCell align="center">
									<Typography className={classes.typoRows} variant="h6">
										<b>Days</b>
									</Typography>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{stepInfo.map((row) => (
								<TableRow key={row.step} >
									<TableCell align="center" className={classes.cell}>
										<Typography className={classes.typoRows} variant="h6">
											{row.step}
										</Typography>
									</TableCell>
									<TableCell align="center" className={classes.cell}>
										<Typography className={classes.typoRows} variant="h3">
											{row.title}
										</Typography>
									</TableCell>
									<TableCell align="center" className={classes.cell}>
										<Typography className={classes.typoRows} variant="h6">
											{row.days}
										</Typography>
									</TableCell>
								</TableRow>
							))}

							<TableRow>
								<TableCell align="center">{null}</TableCell>
								<TableCell align="center">{null}</TableCell>
								<TableCell align="center">
									<Typography className={classes.typoRows}><b>20 Days</b></Typography>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
			<Grid item lg={8} md={10} sm={10} xs={10} className={classes.titleWrapper}>
				<Typography className={classes.typoTitle} variant="h5">
					Lead time mentioned for the very first net. Processing time can reduce
					when batched with quantity and if in sync with the existing codes that
					run.
				</Typography>
			</Grid>
		</Grid>
	);
}
