import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import CallIcon from '@mui/icons-material/Call';
import React from 'react';

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: '10rem',
		marginTop: '3rem',
		[theme.breakpoints.down('md')]: {
			alignItems: 'center',
			marginTop: '1rem',
			justifyContent: 'center',
		},
		[theme.breakpoints.only('xs')]: {
			marginTop: '2rem',
		},
	},
	hrule: {
		border: '1px solid rgb(217, 217, 217)',
		marginTop: '9rem',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		[theme.breakpoints.down('md')]: {
			alignItems: 'center',
			marginTop: '4rem',
			justifyContent: 'center',
		},
	},
	spacings: {
		marginTop: '2rem',
		[theme.breakpoints.down('md')]: {
			textAlign: 'center',
			fontSize: '22px',
		},
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
			fontSize: '18px',
		},
	},
	typoText: {
		[theme.breakpoints.down('md')]: {
			textAlign: 'center',
			fontSize: '24px',
		},
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
			fontSize: '20px',
		},
	},
	factoryGrid: {
		[theme.breakpoints.down('md')]: {
			marginTop: '2.5rem',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '2.5rem',
		},
	},
	contactDetails: {
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: '1rem',
	},
	childGrid: {
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('md')]: {
			justifyContent: 'center',
		},
	},
	iframesets: {
		border: '0px',
		width: '7rem',
		height: '7rem',
	},
	mapslink: {
		[theme.breakpoints.down('md')]: {
			textAlign: 'center',
		},
	},
	officeGrid: {
		[theme.breakpoints.down('md')]: {
			marginTop: '1.5rem',
		},
	},
}));
export default function Footer() {
	const classes = useStyles();
	return (
		<>
			<Grid container justifyContent="center">
				<Grid item lg={12} md={12} sm={10} xs={10} className={classes.hrule} />
			</Grid>
			<Grid container className={classes.root}>
				<Grid item lg={4} md={4} sm={10} xs={10}>
					<Typography fontFamily="Poppins" className={classes.typoText}>
						<b>Excel Nets</b>
					</Typography>
					<Typography fontFamily="Poppins" className={classes.spacings}>
						2022 © ExcelNets <br />
						All rights reserved
					</Typography>
					<br />
					<Typography fontFamily="Poppins"></Typography>
				</Grid>
				<Grid item lg={4} md={4} sm={10} xs={10} className={classes.officeGrid}>
					<Typography fontFamily="Poppins" className={classes.typoText}>
						<b>Office</b>
						<br />
					</Typography>
					<Typography fontFamily="Poppins" className={classes.spacings}>
						No 15, North Phase, <br />
						Guindy Industrial Estate, Ekkaduthangal,
						<br /> Chennai 600032 <br />
					</Typography>
					<div className={classes.contactDetails}>
						<div className={classes.childGrid}>
							<AttachEmailIcon fontSize="small" />
							<Typography fontFamily="Poppins">
								{' '}
								: excelnets.tn@gmail.com
							</Typography>
						</div>
						<div className={classes.childGrid}>
							<CallIcon fontSize="small" />
							<Typography fontFamily="Poppins"> : 044-2225-1753</Typography>
						</div>
					</div>
					<div className={classes.mapslink}>
						<br />
						<iframe
							title="svalar-sq"
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.1837762339605!2d80.20576971532556!3d13.023966117249762!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52674e06c80ef9%3A0x31e3b16af26cc201!2sSVALAR%20SQUARE!5e0!3m2!1sen!2sin!4v1676752265627!5m2!1sen!2sin"
							width="600"
							height="450"
							className={classes.iframesets}
							allowFullScreen=""
							loading="lazy"
							referrerPolicy="no-referrer-when-downgrade"
						></iframe>
					</div>
				</Grid>
				<Grid
					item
					lg={4}
					md={4}
					sm={10}
					xs={10}
					className={classes.factoryGrid}
				>
					<Typography fontFamily="Poppins" className={classes.typoText}>
						<b>Factory</b>
						<br />
					</Typography>
					<Typography fontFamily="Poppins" className={classes.spacings}>
						254-B, <br /> Thirumudivakkam Thiruneermalai Main Road,
						<br /> Thirumudivakkam, Chrompet,
						<br /> Chennai 600040
					</Typography>
					<div className={classes.mapslink}>
						<br />
						<iframe
							title="svalar-sq"
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7776.045162436739!2d80.08539672641173!3d12.970406818548678!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52f55a54329f57%3A0x9ae0c07eff4a14dd!2sThirumudivakkam%2C%20Tamil%20Nadu!5e0!3m2!1sen!2sin!4v1676753040637!5m2!1sen!2sin"
							width="600"
							height="450"
							className={classes.iframesets}
							allowFullScreen=""
							loading="lazy"
							referrerPolicy="no-referrer-when-downgrade"
						></iframe>
					</div>
				</Grid>
			</Grid>
		</>
	);
}
