import { Collapse, Grid, Slide, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../assets/logo.png';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		marginBottom: '2rem',
		[theme.breakpoints.down('md')]: {
			justifyContent: 'space-between',
			marginBottom: '0rem',
		},
	},
	logoWrapper: {
		justifyItems: 'start',
		display: 'flex',
		[theme.breakpoints.down('md')]: {
			justifyContent: 'center',
		},
	},
	itemsWrapper: {
		marginLeft: '5rem',
		display: 'flex',
		[theme.breakpoints.down('md')]: {
			marginLeft: '0rem',
			justifyContent: 'space-evenly',
			// can add better styling for spacing here
		},
	},
	marginItems: {
		margin: '2.5rem',
		display: 'flex',
		cursor: 'pointer',
		color: 'black',
		textDecoration: 'none',
		fontFamily: 'Poppins',
		textDecorationColor: 'black',
		[theme.breakpoints.down('md')]: {
			display: 'none',
			margin: '0rem',
		},
	},
	activeItem: {
		margin: '2.5rem',
		display: 'flex',
		color: theme.palette.primary.main,
		fontWeight: 600,
		fontFamily: 'Poppins',
		cursor: 'pointer',
		[theme.breakpoints.down('md')]: {
			display: 'none',
			margin: '0rem',
		},
	},
	responsiveNav: {
		display: 'none',
		[theme.breakpoints.down('md')]: {
			display: 'flex',
		},
	},
	logoTypo: {
		fontFamily: 'Poppins',
		fontWeight: '550',
		fontSize: '26px',
		[theme.breakpoints.down('md')]: {
			fontWeight: '600',
			fontSize: '18px',
			marginLeft: '15px',
		},
	},
	mobileContainer: {
		flexDirection: 'column',
		alignContent: 'center',
		justifyContent: 'center',
		textAlign: 'center',
	},
	itemLink: {
		borderBottom: '1px solid #7F7F7F',
		width: '90%',
		padding: '1rem',
		[theme.breakpoints.down('md')]: {
			fontFamily: 'Poppins',
		},
	},
	defaultState: {
		width: '90%',
		textDecoration: 'none',
		color: 'black',
		textDecorationColor: 'black',
		padding: '1rem',
		fontFamily: 'Poppins',
		textAlign: 'center',
		[theme.breakpoints.down('md')]: {},
	},
	activeState: {
		width: '90%',
		textDecoration: 'none',
		color: theme.palette.primary.main,
		fontWeight: 600,
		padding: '1rem',
		fontFamily: 'Poppins',
		textAlign: 'center',
		[theme.breakpoints.down('md')]: {},
	},
	imgLogo: {
		width: '50%',
		hieght: '50%',
		[theme.breakpoints.down('md')]: {
			width: '30%',
			hieght: '30%',
		},
		[theme.breakpoints.down('sm')]: {
			width: '60%',
			hieght: '50%',
		},
	},
}));

export default function Navbar() {
	const classes = useStyles();
	const theme = useTheme();
	const containerRef = React.useRef(null);
	const [open, setOpen] = useState(false);

	const handy = theme.breakpoints.down('down');

	const paths = [
		{
			text: 'Home',
			path: '/',
		},
		{
			text: 'About Us',
			path: '/about-us',
		},
		{
			text: 'Contact',
			path: '/contact',
		},
	];

	return (
		<>
			<Grid
				container
				alignItems="center"
				flexDirection={'row'}
				className={classes.root}
			>
				<Grid item lg={2} md={2} sm={4} xs={4} className={classes.logoWrapper}>
					<img
						src={logo}
						alt="excelnets-logo"
						width={'50%'}
						height="50%"
						className={classes.imgLogo}
					/>
				</Grid>
				<Grid item lg={10} md={10} sm={2} xs={2}>
					<Grid
						container
						className={classes.itemsWrapper}
						spacing={handy ? 0 : 8}
					>
						{paths.map((item) => {
							return (
								<NavLink
									key={item.text}
									to={item.path}
									className={({ isActive }) => {
										return clsx(classes.marginItems, {
											[classes.activeItem]: isActive,
										});
									}}
								>
									{item.text}
								</NavLink>
							);
						})}

						<Grid
							item
							className={classes.responsiveNav}
							onClick={() => setOpen(!open)}
						>
							<MenuTwoToneIcon />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{open && <div ref={containerRef} />}
			<Collapse
				direction="down"
				in={open}
				container={containerRef.current}
				mountOnEnter
				unmountOnExit
			>
				<Grid container className={classes.mobileContainer}>
					{paths.map((item) => {
						console.log({ item });
						return (
							<Grid
								item
								sm={12}
								xs={11}
								className={classes.itemLink}
								key={item.text}
								alignContent="center"
							>
								<NavLink
									key={item.text}
									to={item.path}
									className={({ isActive }) => {
										return clsx(classes.defaultState, {
											[classes.activeState]: isActive,
										});
									}}
									onClick={() => {
										setOpen(!open);
									}}
								>
									{item.text}
								</NavLink>
							</Grid>
						);
					})}
				</Grid>
			</Collapse>
		</>
	);
}
