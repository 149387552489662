import { StyledEngineProvider, ThemeProvider, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import './App.css';
import Page from './components/Page';

const useStyles = makeStyles({
	root: {
		// backgroundColor: 'rgb(237, 237, 237)',
    backgroundColor:'white',
		minHeight: '100vH',
	},
});

// lg={} md={} sm={} xs={}

function App() {
	const classes = useStyles();

	const theme = useTheme();

	return (
		<ThemeProvider theme={theme}>
			<StyledEngineProvider injectFirst>
        {/* <AppContextProvider> */}
				<div className={classes.root}>
					<Page />
				</div>
        {/* </AppContextProvider> */}
			</StyledEngineProvider>
		</ThemeProvider>
	);
}

export default App;
